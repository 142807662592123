<template>
  <MiscellaneousPlatform dialog-name="Platforms" />
</template>
<script>
import MiscellaneousPlatform from '@/views/app/miscellaneous/Platform'

export default {
  name: 'PlatformWrapper',
  components: {
    MiscellaneousPlatform
  }
}
</script>
